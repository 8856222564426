.claims-details-card {
  width: 1100px;
  height: fit-content;
  background: #ffffff;
  border-radius: 8px;
}

.claims-details-row1 {
  margin: 30px;
  border-top: 1px solid #d2d1d1;
  margin-bottom: 2opx;
}
.claims-details-row2 {
  margin: 30px;
}
.claims-details-first-column {
  padding: 30px;
  text-align: left;
}
.claims-details-second-column {
  padding: 30px;
  text-align: left;
}
.claims-details-title {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
.claims-span {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.claims-details-card-title {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #444a4a;
}
.claims-button {
  width: 100px;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #da930a;
}
.claims-periods {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.1em;
  color: #5f6868;
}
.claims-period {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.claims-date {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #444a4a;
}
