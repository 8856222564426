.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.nav-bar {
  background-color: #ffff;
}
.navbar-brand {
  color: #0033a1;
  font-weight: 500;
  font-size: 18px;
  /* font-family: "Roboto"; */
  font-style: normal;
  line-height: 21px;
}
#navbarScroll {
  text-align: center;
}
.navbar-menu {
  font-weight: 500;
  font-size: 16px;
  /* font-family: "Roboto"; */
  font-style: normal;
  color: #555c65;
}
/* Dropdown Button */
/* .dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
} */
/* The search field */
#myInput {
  box-sizing: border-box;
  /* background-image: url('searchicon.png'); */
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}
/* The search field when it gets focus/clicked on */
#myInput:focus {
  outline: 3px solid #ddd;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  border: 1px solid #ddd;
  z-index: 1;
}
/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}
.icon {
  margin-right: 20px;
}
.active-link{
  color: #0033a1;
}
.non-active-link{
  color: #555C65;
}
