.sidebar_links_row {
  padding-left: 10px;
  padding-top: 10%;
}
.sidebar_links {
  padding: 5%;
  font-weight: bold;
  border-radius: 5px;
}
.sidebar_links:hover {
  background-color: #ffffff;
}

.sidebar_link_txt {
  font-size: x-small;
  color: #5f6868;

  text-decoration: none;
}
#sidebar_container {
  background-color: #f2f3f3;
  align-content: center;
}
.side_bar_ul {
  min-width: 150px;
}
/* .li::selection { background: yellow; } */
/* .sidebar-active-link{
  color: #0033a1;
}
.sidebar-non-active-link{
  color: #555C65;
} */
