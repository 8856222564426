.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upper img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.user {
  position: relative;
}

.profile img {
  height: 80px;
  width: 80px;
  margin-top: 2px;
}

.profile {
  position: absolute;
  top: -50px;
  left: 5%;
  height: 80px;
  width: 80px;
  /* border: 3px solid #0033a1;
  border-radius: 50%; */
}
.profile-text-admin {
  margin-left: 5%;
  margin-top: 5%;
}

.follow {
  background-color: #0033a1;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 165px;
  right: 20px;
}

.stats span {
  font-size: 29px;
}

/* CSS for the notifications */

.notifications-card-header {
  background-color: transparent;
  border-bottom: none;
  padding: 25px 20px;
}
.notifications-card-header2 {
  font-size: 24px;
  font-weight: 500;
  color: #0a2240;
}
/* .card-block {
  padding: 1.25rem;
  margin-top: -40px;
} */

.notifications-card-block {
  padding: 1.25rem;
  margin-top: -40px;
  line-height: 25px;
}

.user-activity-card .u-img .cover-img {
  width: 40px;
  height: 40px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.text-muted-border {
  color: #262b2b !important;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #c4c4c4;
  font-size: 14px;
  font-weight: 300;
}

/* CSS for card tabs */
body {
  background-color: #f7f8f9;
}
.tab {
  padding-top: 6%;
  font-size: 16px;
  color: #444a4a;
}

.tabs {
  box-shadow: none;
  margin-bottom: 3px;
  padding-top: 5%;
}

.card-email {
  width: 30px;
  height: 14px;
  left: 327px;
  top: 566px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.card-email2 {
width: 214px;
height: 19px;
left: 327px;
top: 586px;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;
color: #444A4A;
}
.card-phonenumber{
width: 81px;
height: 14px;
left: 696px;
top: 566px;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
color: #000000;
}
.card-phonenumber2 {
width: 89px;
height: 19px;
left: 696px;
top: 586px;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;
color: #444A4A;
}
.card-company-id{
width: 65px;
height: 14px;
left: 327px;
top: 649px;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
color: #000000;
margin-top: 30px;
}
.card-company-id2 {
width: 55px;
height: 19px;
left: 327px;
top: 670px;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;
color: #444A4A;
}
.activity-card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #000000;
  line-height: 19px;
  /* width: 28px;
  height: 19px; */
}
.activity-card-date {
  font-size: 12px;
  font-weight: 400;
  color: #444a4a;
  padding-left: 60%;
  line-height: 14px;
}
.activity-card-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #262b2b;
}
.admin-profile-button {
width: 90px;
height: 40px;
left: 1335px;
top: 528px;
background: #052A6C;
border-radius: 8px;
margin-left: 500px;
}
.admin-profile-page-card {
  width: 100%;
  height: fit-content;
  margin-top: 20px;
}
.activity-border {
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
}
.shareholder1 {
  /* width: 93px; */
  height: 19px;
  font-style: normal;
  /* font-weight: 500; */
  /* font-size: 16px; */
  color: #000000;
}
.admin-name {
  width: 228px;
  height: 31px;
  left: 327px;
  top: 409px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.admin-role {
  width: 79px;
  height: 19px;
  left: 327px;
  top: 443px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.functional-groups {
width: 228px;
height: 31px;
left: 323px;
top: 526px;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
color: #000000;
}
.functional-groups-button {
width: 90px;
height: 40px;
/* background-color: #052A6C; */
border-radius: 8px;
margin-left: 500px;
}
.functional-group-name {
width: 228px;
height: 31px;
left: 343px;
top: 574px;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
color: #000000;
}
.functional-group-name-value {
width: 228px;
height: 56px;
left: 343px;
top: 603px;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: center;
color: #000000;
}
.individual-settings {
width: 301px;
height: 31px;
left: 323px;
top: 720px;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
color: #000000;
}
.individual-settings-headers {
width: 124px;
height: 31px;
left: 343px;
top: 768px;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 23px;
color: #000000;
}
.individual-settings-rows {
width: 196px;
height: 56px;
left: 343px;
top: 807px;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: center;
color: #000000;
}
.fg-delete-modal{
width: 536px;
height: 104px;
font-style: normal;
font-weight: 500;
font-size: 24px;
align-items: center;
text-align: center;
color: #000000;
}

