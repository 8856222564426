.requests-details-card {
  width: 1100px;
  height: fit-content;
  background: #ffffff;
  border-radius: 8px;
}
.requests-details-button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background-color: #da930a;
}
.requests-details-row1 {
  margin: 30px;
  border-top: 1px solid #d2d1d1;
  border-bottom: 1px solid #d2d1d1;
  margin-bottom: 2opx;
}
.requests-details-row2 {
  margin: 30px;
}
.requests-details-first-column {
  padding: 30px;
  text-align: left;
}
.requests-details-second-column {
  padding: 30px;
  text-align: left;
}
.requests-old-details {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.1em;
  color: #5f6868;
}
.requests-old-details-name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
}
.requests-old-details-name2 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #444a4a;
}
.requests-old-details-country {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
.requests-old-details-country2 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #444a4a;
}
.requests-details-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
.requests-span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.requests-details-card-title {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  color: #444a4a;
}
.requests-button {
  width: 100px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #da930a;
}
