.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upper img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.user {
  position: relative;
}

.profile img {
  height: 80px;
  width: 80px;
  margin-top: 2px;
}

.profile {
  position: absolute;
  top: -50px;
  left: 5%;
  height: 80px;
  width: 80px;
  /* border: 3px solid #0033a1;
  border-radius: 50%; */
}
.profile-text {
  margin-left: 5%;
  margin-top: 10%;
}

.follow {
  background-color: #0033a1;
  color: #fff;
  font-weight: bold;
  position: absolute;
  top: 165px;
  right: 20px;
}

.stats span {
  font-size: 29px;
}

/* CSS for the notifications */

.notifications-card-header {
  background-color: transparent;
  border-bottom: none;
  padding: 25px 20px;
}
.notifications-card-header2 {
  font-size: 24px;
  font-weight: 500;
  color: #0a2240;
}
/* .card-block {
  padding: 1.25rem;
  margin-top: -40px;
} */

.notifications-card-block {
  padding: 1.25rem;
  margin-top: -40px;
  line-height: 25px;
}

.user-activity-card .u-img .cover-img {
  width: 40px;
  height: 40px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.text-muted-border {
  color: #262b2b !important;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: #c4c4c4;
  font-size: 14px;
  font-weight: 300;
}

/* CSS for card tabs */
body {
  background-color: #f7f8f9;
}
.tab {
  padding-top: 6%;
  font-size: 16px;
  color: #444a4a;
}

.tabs {
  box-shadow: none;
  margin-bottom: 3px;
  padding-top: 5%;
}
/* .tab-text {
  color: #919aa3;
} */
.card-title2 {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  color: #000000;
  line-height: 14px;
  /* width: 30px;
  height: 14px; */
}
.tab-text2 {
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  color: #444a4a;
  line-height: 19px;
  width: 215px;
  height: 19px;
}
.activity-card-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #000000;
  line-height: 19px;
  /* width: 28px;
  height: 19px; */
}
.activity-card-date {
  font-size: 12px;
  font-weight: 400;
  color: #444a4a;
  padding-left: 60%;
  line-height: 14px;
}
.activity-card-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #262b2b;
}
.profile-button {
  /* margin-top: 5px;
  margin-left: 8.5%; */
  margin-left: 60%;
  background-color: #052a6c;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 100px;
  height: 35px;
  color: #ffffff;
}
.landing-page-card {
  width: 100%;
  height: 750px;
  /* margin-right: -35px; */
}
.activity-border {
  border-top: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
}
.shareholder1 {
  /* width: 93px; */
  height: 19px;
  font-style: normal;
  /* font-weight: 500; */
  /* font-size: 16px; */
  color: #000000;
}
