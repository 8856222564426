.full_blue_bg {
  background-image: linear-gradient(#0033A1,#000029);
  height: 100vh;
}
/* .main_row {
  display: flex;
  align-items: center;
} */
.main_column {
  background-color: #ffffff;
}
.login_card {
  width:100%;
  margin-top: 5%;
  background-color: #ffffff;
  height: 370px;
}
#login_btn{
  background-color: #0033A1;
  width: 100%;}
/* .login_btn{
  color: #0033A1;
  width: 100%;
} */
#b_boarder_input{
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0%;
}
#input_icon {
  margin-right: 0px;
  padding-right: 0%;
}
#card_title_custom {
  padding-top: 50px;
  padding-bottom: 15px;
  font-size: medium;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.logo_img {
  /* display: flex;
  justify-items: center; */
  /* justify-content: center; */
  /* margin-left: 38%; */
}

.primary-header {
  color: #ffffff;
  display: flex;
  justify-content: center;
  text-align: center;
}
/* .center_row {
  margin-top: 30%;

} */
#f_p_link {
  font-size: small;
  color: #0033A1;
}